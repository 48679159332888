import { Col, Row, Spin } from 'antd';
import React, { FC, lazy, Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';

//Images
import plane1 from './assets/plane1.jpg';
import plane2 from './assets/plane2.jpg';
import plane3 from './assets/plane3.jpg';
import plane4 from './assets/plane4.jpg';
import plane5 from './assets/plane5.jpg';

//Components
const ManageCfis = lazy(() => import('./screens/ManageCfis'));
const GetAccess = lazy(() => import('./screens/GetAccess'));
const Feed = lazy(() => import('./screens/Feed'));
const LegalStatement = lazy(() => import('./screens/LegalStatement'));
const Terms = lazy(() => import('./screens/Terms'));
const AddReview = lazy(() => import('./screens/AddReviews'));
const CookiesPage = lazy(() => import('./screens/Cookies'));
const DCMA = lazy(() => import('./screens/DCMA'));
const FAQ = lazy(() => import('./screens/FAQ'));
const ReviewGuide = lazy(() => import('./screens/ReviewGuide'));
const CfiListing = lazy(() => import('./screens/CfiListing'));
const CfiDetail = lazy(() => import('./screens/CfiDetail'));
const SiteRules = lazy(() => import('./screens/SiteRules'));
const ThankYou = lazy(() => import('./screens/ThankYou'));
const ManageReviews = lazy(() => import('./screens/ManageReviews'));
const CustomRoute = lazy(() => import('./components/common/CustomRoute'));
const Blog = lazy(() => import('./screens/Blog'));
const AddBlog = lazy(() => import('./screens/AddBlog'));
const Blogs = lazy(() => import('./screens/Blogs'));
const ManageBlogs = lazy(() => import('./screens/Manageblogs'));
const EditBlog = lazy(() => import('./screens/EditBlog'));

const Common: FC = () => {
  return (
    <Suspense
      fallback={
        <Row justify="center" className="centered">
          <Col>
            <Spin size="large" />
          </Col>
        </Row>
      }
    >
      <Switch>
        <CustomRoute path="/legalStatement" component={<LegalStatement />} img={plane4} />
        <CustomRoute path="/cookies" component={<CookiesPage />} img={plane3} />
        <CustomRoute path="/reviewguidelines" component={<ReviewGuide />} img={plane1} />
        <CustomRoute path="/dcma" component={<DCMA />} img={plane5} />
        <CustomRoute path="/faq" component={<FAQ />} img={plane2} />
        <CustomRoute path="/siterules" component={<SiteRules />} img={plane3} />
        <CustomRoute path="/terms" component={<Terms />} img={plane5} />
        <CustomRoute path="/getAccess" component={<GetAccess />} />
        <CustomRoute path="/managecfis" component={<ManageCfis />} img={plane5} />
        <CustomRoute path="/managereviews" component={<ManageReviews />} img={plane2} />
        <CustomRoute path="/cfis/:cfiNumber" component={<CfiDetail />} img={plane2} />
        <CustomRoute path="/search" component={<CfiListing />} img={plane3} />
        <CustomRoute path="/cfis" component={<CfiListing />} img={plane2} />
        <CustomRoute path="/usacfis" component={<CfiListing />} img={plane5} />
        <CustomRoute path="/eurcfis" component={<CfiListing />} img={plane1} />
        <CustomRoute path="/addReview/:cfiNumber" component={<AddReview />} img={plane2} />
        <CustomRoute path="/addReview" component={<AddReview />} img={plane5} />
        <CustomRoute path="/readBlog/:blogid" component={<Blog />} img={plane5} />
        <CustomRoute path="/blogs" component={<Blogs />} img={plane5} />
        <CustomRoute path="/addBlog" component={<AddBlog />} img={plane5} />
        <CustomRoute path="/editBlog" component={<EditBlog />} img={plane5} />
        <CustomRoute path="/thankyou" component={<ThankYou />} img={plane1} />
        <CustomRoute path="/manageblogs" component={<ManageBlogs />} img={plane5} />
        <CustomRoute path="/" component={<Feed />} img={plane4} />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

export default Common;
